














import {Component, Prop, Vue} from "vue-property-decorator";
import AuthService from "@/services/AuthService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";

@Component
export default class TaxationCheckElement extends Vue{

    @Prop() private message!: string | null;

    private successful = false;

    mounted(){
        if(!this.$route.params.confirmationCode){
            return;
        }
        AuthService.confirmEmail(this.$route.params.confirmationCode).then(
            response => {
                this.successful = true;
                this.message = this.$t('auth.email_confirmed') as string
            },
            error => {
                this.successful = false;
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
            }
        )
    }

}
